.faq-top {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin-top: 2.5vh;
    @media (max-width: $layout-breakpoint-xsmall) {
        width: 90%;
    }
    .admin-options {
        position: absolute;
        display: flex;
        top: 0;
        right: 0;
    }
    & h1 {
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 50px;
    }
    .faq-item-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 50px;
        .faq-item-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            h3 {
                font-size: 1.5rem;
                font-weight: 600;
                &:hover {
                    cursor: pointer;
                    color: $perennia-grey;
                    transition: 0.4s ease-in-out;
                }
            }
            button {
                background-color: transparent;
                border: none;
                outline: none;
                font-size: 1.5rem;
                &:hover {
                    cursor: pointer;
                    color: $perennia-grey;
                    transition: 0.4s ease-in-out;
                }
                
            }
        }
        .faq-item-body {
            display: none;
            width: 100%;
            margin-top: 20px;
            padding-left: 10px;
            margin-bottom: 5vh;
            &.active {
                display: flex;           
            }
            p {
                font-size: 1.2rem;
                font-weight: 400;
                line-height: 1.5;
                animation-name: fadeIn;
                animation-iteration-count: 1;
                animation-timing-function: ease-out 0s 1;
                animation-duration: 1s;
                @keyframes fadeIn {
                  0% {
                    opacity: 0;
                  }
                  100% {
                    opacity: 1;
                  }
                }
            }
        }
    }
}
.faq-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-top: 2.5vh;
    padding-bottom: 2.5vh;
    @media (max-width: $layout-breakpoint-xsmall) {
        width: 90%;
    }
    & h1 {
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 50px;
    }
}
.admin-add-modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 50%;
    transform: translateY(-50%);
    left: 0%;
    z-index: 500;
    transition: 650ms;
    &.is-notActive {
        display: none;
    }

    .admin-edit-add-new-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
    }

    .warning-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        & p {
            text-align: center;
            margin: 15px;
            &.warning-icon {
                font-size: 50px;
                color: $perennia-red;
            }
        }

        .warning-btn-container {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;
        }
    }
}
.add-item-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 150px;
    margin-bottom: 5em;
    #fine-print {
        font-size: 0.8em;
        margin-left: 15px;
        margin-top: -1em;
    }
    .add-item-button-container {
        display: flex;
        justify-content: space-between;
    }
    .file-description {
        display: flex;
        align-items: center;
        font-size: 1em;
        margin-top: -10px;
        margin-left: 20px;
    }
    .tag-use-text {
        font-size: 12px;
        color: $perennia-green;
        margin-left: 25px;
        width: 80%;
    }
}
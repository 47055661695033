.error-page-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: $white;
    background-position: center;
    background-size: cover;
    z-index: 1000;
    .error-text {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        & h1 {
            font-size: 50rem;
            letter-spacing: 25px;
            font-weight: 900;
            color: $perennia-grey;
            text-transform: uppercase; 
            @media (max-width: $layout-breakpoint-xsmall) {
                font-size: 20rem;
            }
        }
    }
    h2 {
        font-size: 75px;
        letter-spacing: 5px;
        font-weight: 500;
        color: $perennia-purple;
        text-transform: uppercase;
        @media (max-width: $layout-breakpoint-xsmall) {
            font-size: 45px;
        }
    }
    p {
        margin: 25px;
        font-size: 24px;
        @media (max-width: $layout-breakpoint-xsmall) {
            font-size: 20px;
        }
    }
}
.deadlines {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .react-calendar {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .react-calendar__navigation {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 48px;
            background-color: $background;
            border-bottom: 1px solid $perennia-grey;
            margin-top: -10px;
            & button {
                background: none;
                border: none;
                outline: none;
                font-size: 24px;
                font-weight: 500;
                &:hover {
                    cursor: pointer;
                    color: $perennia-teal;
                    transition: 0.5s ease-in-out;
                }
            }
        }
        .react-calendar__viewContainer {
            width: 100%;
            height: 100%;
            .react-calendar__month-view__weekdays {
                height: 48px;
                width: 100%;
                .react-calendar__month-view__weekdays__weekday {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    text-decoration: none;
                }
                
            }
            .react-calendar__month-view__days {
                display: grid;
                grid-template-columns: 3fr 3fr 3fr 3fr 3fr 3fr 3fr;
                padding: 0.25em;
                height: 100%;
                width: 100%;
                & button {
                    display: flex;
                    justify-content: flex-start;
                    height: 10rem;
                    background: none;
                    border-radius: 5px;
                    border: 1px solid $background;
                    & abbr {
                        font-size: 20px;
                    }
                    &:hover {
                        cursor: pointer;
                        background: $background;
                        transition: 0.15s ease-in-out;
                    }
                }
            }
        }
    }
}
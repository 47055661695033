// Defaults
$step: 20px;

.admin-cat-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 80%;
    margin-top: 50px;
    & p {
        font-size: 30px;
        font-weight: 500;
    }
    
    .admin-cat-header-btn-container {
        display: flex;
        justify-content: flex-end;
        height: 5vh;
        width: 100%;
    }
    @media (max-width: $layout-breakpoint-small) {
      width: 100%;
      margin-bottom: 25px;
    }
}

.cat-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 80%;
    padding: 10px;
    margin: 0 auto;
    @media (max-width: $layout-breakpoint-small) {
      width: 100%;
    }
}

.cat-card {
    border: 1px solid $perennia-grey;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100%;
    animation-name: fadeIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-out 0s 1;
    animation-duration: 0.5s;
    @keyframes fadeIn {
      0% {
          opacity: 0;
      }
      100% {
          opacity: 1;
      }
    }

    .cat-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        width: 100%;
        padding-left: 10px;
        color: $white;
        &.level-0 {
            background-color: $perennia-blue;
          }
          &.level-1 {
            background-color: $perennia-teal;
          }
          &.level-2 {
            background-color: $perennia-purple;
          }
          &.level-3 {
            background-color: $perennia-orange;
          }
          &.level-4 {
            background-color: $perennia-light-blue;
          }
        
        & p {
            display: flex;
            justify-content: center;
            align-items: center;
            & span {
                margin-right: 5px;
            }
            &:hover {
              cursor: pointer;
            }
        }

        .cat-card-header-btn-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 50px;
            margin-right: 10px;
            & p:hover {
                cursor: pointer;
                color: $perennia-grey;
                transition: 0.5s ease-in-out;
            }
        }
    }

  }
  
  .children {
    overflow: hidden;
    transition: 850ms;
    background-color: $white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    animation-name: fadeIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-out 0s 1;
    animation-duration: 0.5s;
    @keyframes fadeIn {
      0% {
          opacity: 0;
      }
      100% {
          opacity: 1;
      }
  }
  
    &.collapsed {
      height: 0;
      transition: 850ms;
    }
  }

  .add-cat-btn-container {
      display: flex;
      justify-content: space-evenly;
      width: 95%;
  }
  
.login-btn {
    border: none;
    outline: none;
    width: 250px;
    height: 48px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 2px;
    color: white;
    background: linear-gradient(
        to right, 
        $perennia-teal 50%, 
        $perennia-purple 50%
        );
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
    margin-top: 25px;
    &:hover {
        cursor: pointer;
        background-position: left bottom;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    }
}

.inputField {
    display: flex;
    flex-direction: column;
    margin: 5px;
    width: 100%;
    padding-left: 10px;
    & label {
        display: flex;
        align-items: center;
        text-transform: capitalize;
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 14px;
        & span {
            margin-left: 5px;
            font-size: 12px;
            color: $danger;
        }
    }
    &.is-notActive {
        display: none;
    }

    .inputField-select {
        display: flex;
        align-items: center;
        text-transform: capitalize;
        font-weight: 500;
        font-size: 14px;
        .toggle-switch {
            position: relative;
            width: 50px;
            display: inline-block;
            text-align: left;
            top: 2px;
            left: 5px;
            .checkbox {
                display: none;
                &:checked + .label .inner {
                    margin-left: 0;
                }
                &:checked + .label .switch {
                    right: 0px;
                }
            }
            .label {
                display: block;
                overflow: hidden;
                cursor: pointer;
                border-radius: 20px;
            }
            .inner {
                display: block;
                width: 200%;
                margin-left: -100%;
                transition: margin 0.3s ease-in 0s;
                &::before,
                &::after {
                    float: left;
                    width: 50%;
                    height: 20px;
                    padding: 0;
                    line-height: 20px;
                    color: $white;
                    font-weight: bold;
                    box-sizing: border-box;
                }
                &::before {
                    content: "YES";
                    padding-left: 5px;
                    font-size: 12px;
                    background-color: $perennia-teal;
                    color: #fff;
                }
                &::after {
                    content: "NO";
                    padding-right: 5px;
                    font-size: 12px;
                    background-color: #bbb;
                    color: #fff;
                    text-align: right;
                }
            }

            .switch {
                display: block;
                width: 16px;
                height: 16px;
                margin: 2px;
                background: #fff;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 28px;
                border: 0 solid #bbb;
                border-radius: 50px;
                transition: all 0.3s ease-in 0s;
            }
        }
    }

    .inputField-input {
        height: 32px;
        border: 1px solid $perennia-grey;
        border-radius: 5px;
        padding-left: 10px;
        margin-bottom: 10px;
        &.ext {
            width: 48px;
        }
    }

    .phone-input {
        display: flex;
        justify-content: space-between;
        width: 100%;
        & input:nth-child(1) {
            width: 75%;
        }
        & div {
            display: flex;
            justify-content: center;
            align-items: center;
            & input {
                margin-left: 10px;
            }
        }
    }

    .inputField-error-message {
        display: block;
        font-size: 10px;
        color: $danger;
    }
}
.textarea {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    .inputField-input-textarea {
        position: relative;
        border: 1px solid $perennia-grey;
        border-radius: 5px;
        padding-left: 10px;
        margin-bottom: 10px;
    }
    .charCount {
        position: absolute;
        display: flex;
        right: 0;
        bottom: -20px;
        & p {
            font-size: 10px;
            & span {
                font-size: 10px;
                &.danger {
                    color: $danger;
                }
            }
        }
    }
}
.inputField-input-select {
    margin-bottom: 10px;
}

@media (max-width: 450px) {
    .phone-input > input:nth-child(1) {
        width: 60%;
    }
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
    .info-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        width: 100%;
        background-color: $perennia-blue;
        border-bottom: 1px solid $perennia-grey;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        color: $white;
        .info-header-left {
            font-size: 24px;
            font-weight: 600;
            text-transform: capitalize;
            margin-left: 10px;
        }
        .info-header-right {
            margin-right: 10px;
    
            .card-header-icon {
                font-size: 16px;
                &:hover {
                    cursor: pointer;
                    color: $perennia-teal;
                    transition: 0.5s ease-in-out;
                }
            }
        }
    }
    .info-body {
        display: flex;
        flex-direction: column;
        object-fit: contain;
        width: 100%;
        height: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        overflow-y: scroll;
        scrollbar-width: thin;
        // &::-webkit-scrollbar {
        //     width: 0px;
        //     background: transparent; 
        // }
        @media(max-width: $layout-breakpoint-xsmall) {
            padding-bottom: 25px;
        }
    }
}









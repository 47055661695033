.dashboard-mngt-btn-container {
    display: flex;
    justify-content: flex-start;
    width: 95%;
    margin-top: 2.5em;
    @media (max-width: $layout-breakpoint-xsmall) {
        width: 100%;
        justify-content: center;
    }
}

.dashboard-main-container {
    display: grid;
    grid-template-columns: 6fr 4fr;
    justify-content: center;
    width: 95%;
    margin-left: -2.5em;
    height: auto;
    @media (max-width: $layout-breakpoint-medium) {
        grid-template-columns: 1fr;
        width: 100%;
        padding: 0;
        margin-left: 0;
    }
    .dashboard-left {
        display: flex;
        flex-direction: column;
        width: 97.5%;
        margin: 0 2em 0 0;
        @media (max-width: $layout-breakpoint-medium) {
            width: 95%;
            margin: 0;
        }
        .draggable {
            color: $white;
        }
    }
    .dashboard-right {
        display: flex;
        flex-direction: column;
        width: 97.5%;
        margin: 0 2em 0 0;
        @media (max-width: $layout-breakpoint-medium) {
            width: 95%;
            min-height: 40vh;
            margin: 0;
        }
    }
}



.notifications {
    position: relative;
    display: flex;
    flex-direction: column;
    .notification-icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px;
        font-weight: 500;
        font-size: 18px;
        &:hover {
            cursor: pointer;
            color: $perennia-grey;
            transition: 0.5s ease-in-out;
        }
        .item-count-badge {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: -5px;
            right: -2.5px;
            background-color: $danger;
            border-radius: 50%;
            height: 10px;
            width: 10px;
        }
    }
    .notification-dropdown-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 40px;
        right: -1px;
        height: auto;
        width: 450px;
        background-color: $white;
        color: $black;
        border-radius: 5px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
        transition: 850ms;
        @media (max-width: $layout-breakpoint-xsmall) {
            right: -38px;
            width: 93.5vw;
        }
        .notifications-header {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 36px;
            width: 90%;
            & h2 {
                font-size: 20px;
            }
            & button {
                background-color: transparent;
                border: none;
                font-size: 18px;
                width: 18px;
                &:hover {
                    cursor: pointer;
                    color: $perennia-grey;
                    transition: 0.5s ease-in-out;
                }
            }
            .options {
                position: absolute;
                top: 24px;
                right: 5px;
                background-color: $white;
                border-radius: 5px;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
                padding: 10px;
                z-index: 1;
                & button {
                    display: flex;
                    width: auto;
                    background-color: transparent;
                    border: none;
                    font-size: 14px;
                    padding: 10px;
                    & span {
                        margin-right: 5px;
                    }
                }
            }
        }
        .notification-card {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 90%;
            margin: 10px;
            .notification {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px;
                margin-top: 10px;
                border-radius: 5px;
                padding: 10px;
                &:hover {
                    background-color: $background;
                    transition: 0.5s ease-in-out;
                    .notification-option-btn > button {
                        visibility: visible;
                        transition: 0.5s ease-in-out;
                    }
                }
                .notification-content {
                    cursor: pointer;
                    .createdAt {
                        font-size: 12px;
                        margin-top: 5px;
                    }
                }
                .notification-option-btn > button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    font-size: 20px;
                    background-color: $white;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
                    visibility: hidden;
                    @media (max-width: $layout-breakpoint-xsmall) {
                        visibility: visible;
                        color: $black;
                        font-size: 16px;
                        background-color: transparent;
                        box-shadow: none;
                        z-index: 1;
                    }
                    &:hover {
                        cursor: pointer;
                        display: flex;
                        transition: 0.5s ease-in-out;
                    }
                }
            }
            .single-item-options {
                position: absolute;
                top: 60px;
                right: 40px;
                background-color: $white;
                border-radius: 5px;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
                padding: 10px;
                z-index: 1;
                & button {
                    cursor: pointer;
                    display: flex;
                    width: auto;
                    background-color: transparent;
                    border: none;
                    font-size: 14px;
                    padding: 10px;
                    & span {
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}
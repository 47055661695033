.idle-session {
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 50%;
    transform: translateY(-50%);
    left: 0%;
    z-index: 500;
    transition: 650ms;
    &.is-active {
        display: flex;
    }
    .idle-timer-warning-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        .idle-session-warning {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 25vh;
            .idle-icon {
                height: 12vh;
                width: 15vh;
                color: $perennia-teal;
                margin-bottom: 25px;
            }
            p {
                width: 80%;
                text-align: center;
                font-size: 18px;
                letter-spacing: 2px;
                color: $black;
            }
        }
        .idle-session-btn-container {
            display: flex;
        }
    }
}
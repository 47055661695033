.admin-add-new-item-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 25vh;

    .admin-new-item-inputs-container {
      display: grid;
      grid-template-columns: auto auto;
      width: 100%;
      @media (max-width: $layout-breakpoint-xsmall) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
    }

    .add-new-title {
      font-size: 24px;
      margin-bottom: 25px;
      margin-left: 25px;
    }

    .admin-add-new-item-submit-btn {
      margin-left: 25px;

    }
    @media (max-width: $layout-breakpoint-xsmall) {
      height: 40vh;
      align-items: center;
    }
}
  
  
  
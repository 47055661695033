.login-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: $white;
    z-index: 250;

    .app-version {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    }
    .login-left-container {
        background-color: $perennia-blue;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100vh;
        width: 60vw;
        overflow: hidden;
        @media (max-width: $layout-breakpoint-small) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 0;
        }
    }
    .login-right-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 40vw;
        background: linear-gradient($white, $background);
        box-shadow: -10px 0px 10px rgba(0, 0, 0, 0.5);
        overflow: hidden;
        & h2 {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 25px;
        }
        & p {
            font-weight: 250;
            text-align: center;
            width: 90%;
        }

        .main-logo {
            width: 60%;
            margin-bottom: 50px;
        }
        @media (max-width: $layout-breakpoint-small) {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            height: 70vh;
            width: 90vw;
            padding: 25px;
            background-color: $white;
            border-radius: 5px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            z-index: 2;
          .main-logo {
                width: 90%;
                @media (max-width: $layout-breakpoint-small) {
                    width: 30%;
                }
            }
        }
        .login-form {
            display: flex;
            flex-direction: column;
            margin-top: 50px;

            label {
                font-size: 14px;
                letter-spacing: 2px;
                margin-bottom: 5px;
            }

            .login-input {
                height: 36px;
                border: 1px solid $perennia-grey;
                border-radius: 5px;
                margin-bottom: 10px;
                padding: 10px;
            }

            .forgot-pw {
                font-size: 10px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .login-show-qr {
            cursor: pointer;
            color: $perennia-teal;
            font-weight: 500;
            margin-left: 5px;
            font-size: 12px;
        }
    }
}


.session-timeout-container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 50%;
    transform: translateY(-50%);
    left: 0%;
    z-index: 500;
    transition: 650ms;
    &.is-notActive {
        display: none;
    }

    .session-idle-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        h2 {
            font-size: 40px;
            margin: 10px;
            font-weight: 600;
            text-transform: uppercase;
            color: $perennia-grey;
        }
        p {
            text-align: center;
            margin: 10px;
            letter-spacing: 2px;
        }

        .session-timeout-btn-container {
            display: flex;
        }
    }
}
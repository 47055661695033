.sidebar-nav-icon,
.sidebar-nav-icon-close,
.sidebar-mobile-logout {
    display: none;
    &:hover {
        cursor: pointer;
        transition: 0.5s ease-in-out;
        transform: scale(1.05);
    }
}
.sidebar-nav {
    position: fixed;
    display: flex;
    justify-content: center;
    background-color: $perennia-blue;
    width: 100px;
    min-height: 100%;
    height: 100%;
    top: 50px;
    left: 0;
    transition: 550ms ease-in-out;
    z-index: 150;
    overflow: hidden;
    box-shadow: 5px 0px 5px $perennia-grey;
    &.active {
        left: 0;
    }
    .sidebar-item-wrapper {
        margin-top: 10px;
        width: 100%;
        .sidebar-link {
            display: flex;
            justify-content: space-between;
            align-items: center;
            list-style: none;
            width: 100%;
            text-decoration: none;
            margin-top: 25px;
            color: $white;
            @media (max-width: $layout-breakpoint-medium) {
                margin-top: 20px;
            }
            .sidebar-label {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 100%;
                font-size: 30px;
                @media (max-width: $layout-breakpoint-medium) {
                    font-size: 24px;
                }
                & span {
                    font-size: 14px;
                    margin-top: 10px;
                    @media (max-width: $layout-breakpoint-medium) {
                        font-size: 12px;
                    }
                }
                &:hover {
                    cursor: pointer;
                    transition: 0.5s ease-in-out;
                    color: $perennia-grey;
                }
            }
        }
    }
}
@media (max-width: $layout-breakpoint-small) {
    .sidebar {
        width: 100%;
        min-height: 0;

        .sidebar-nav-icon {
            position: fixed;
            display: flex;
            top: 10px;
            left: 10px;
            font-size: 24px;
            color: $white;
            z-index: 201;
        }
    }
    .sidebar-nav {
        left: -100%;
        top: 0;
        width: 50%;
        z-index: 201;
        box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.25);
        // box-shadow: none;
        .sidebar-item-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 10px;
            width: 100%;
            .sidebar-nav-icon-close {
                position: absolute;
                display: flex;
                right: 25px;
                font-size: 24px;
                color: $white;
            }
            .sidebar-mobile-logout {
                position: absolute;
                display: flex;
                right: 100px;
                top: 15px;
                font-size: 16px;
                color: $white;
                & span {
                    margin-right: 5px;
                    font-size: 16px;
                }
            }
            .sidebar-link {
                display: flex;
                justify-content: space-between;
                align-items: center;
                list-style: none;
                width: 90%;
                text-decoration: none;
                margin-top: 30px;
                color: $white;
                .sidebar-label {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    text-align: center;
                    width: 90%;
                    margin: 10px;
                    font-size: 30px;
                    & span {
                        font-size: 14px;
                        margin-top: 0;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
@media (max-width: $layout-breakpoint-xsmall) {
    .sidebar-nav {
        width: 100%;
        box-shadow: none;
    }
}

.action-btns-container {
    display: flex;
    justify-content: space-between;
    width: 50px;
    & p:hover {
        cursor: pointer;
        color: $perennia-grey;
        transition: 0.5s ease-in-out;
    }
}
.items-table-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: visible !important;
    .items-table-header {
        position: absolute;
        display: flex;
        align-items: center;
        width: 100%;
        height: 36px;
        top: 0;
        left: 0;
        margin-top: -10px;
        background-color: $background;
        border-bottom: 1px solid $perennia-grey;
        z-index: 1;
        .items-table-button-container {
            position: absolute;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100px;
            height: 100%;
            right: 10px;
            & p {
                font-size: 20px;
                &:hover {
                    cursor: pointer;
                    color: $perennia-grey;
                    transition: 0.5s ease-in-out;
                }
            }
        }
       
    }
    #claims-and-invoices-table {
        height: 100%;
        width: 100%;
        margin-top: 15px;
        .ag-row, .ag-row-first {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 42px !important;
            font-size: 14px;
            &:hover {
                background-color: $background;
            }
        }
    }
    .invoice-footer {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 15%;
        bottom: -10px;
        z-index: 1;
        background-color: $white;
        border-top: 1px solid $perennia-grey;
        .invoice-footer-left,
        .invoice-footer-right {
            display: flex;
            & p {
                font-size: 11px;
                & span {
                    font-size: inherit;
                    font-weight: 600;
                    margin: 0 5px;
                }
            }
        }
        @media (max-width: $layout-breakpoint-xsmall) {
            .invoice-footer-left {
                display: none;
            }
        }
        & p {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 20px;
            margin: 5px;
            @media (max-width: $layout-breakpoint-xsmall) {
                font-size: 12px;
            }
            .invoice-footer-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $danger;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                margin-left: 5px;
                color: $white;
                &.is-active {
                    background-color: $perennia-green;
                }
                @media (max-width: $layout-breakpoint-xsmall) {
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }
}


.address-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: -15px;
    .address-main-info,
    .address,
    .address-created-info,
    .address-emails,
    .address-phone {
        display: flex;
        flex-direction: column;
        margin: 10px;
        width: 95%;
        border-bottom: 1px dotted $perennia-grey;
        &:last-child {
            border-bottom: none;
        }
        .address-companyName {
            color: $perennia-teal;
            &:hover {
                color: $perennia-blue;
                transition: 0.5s ease-in-out;
            }
        }
        .address-contact-name {
            font-size: 20px;
            font-weight: 600;
            span {
                font-size: 20px;
            }
        }
        & p {
            font-size: 14px;
            margin: 5px;
            font-weight: 600;
            & span {
                font-size: 12px;
                text-transform: capitalize;
                margin-left: 5px;
                margin-right: 5px;
                font-weight: 400;
                & a {
                    color: $perennia-teal;
                    text-transform: none;
                    &:hover {
                        cursor: pointer;
                        color: $perennia-blue;
                        transition: 0.5s ease-in-out;
                    }
                }
            }
        }
        .address-contact-isPrimary {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 24px;
            width: 150px;
            background-color: $perennia-purple;
            color: $white;
            border-radius: 5px;
            margin: 5px;
        }
    }
    .address-emails {
        & p {
            display: flex;
            flex-direction: column;
            & span {
                margin: 5px 0;
            }
        }
    }
    .company-isFunder {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        width: 56px;
        border-radius: 5px;
        background-color: $perennia-orange;
        margin: 5px;
    }
    .status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & p {
            align-items: center;
        }
        & button {
            display: flex;
            background: none;
            border: none;
            outline: none;
            &:hover {
                cursor: pointer;
                color: $perennia-grey;
                transition: 0.4s ease-in-out;
            }
        }
    }
}

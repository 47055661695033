// Breakpoints
$layout-breakpoint-large: 1920px;
$layout-breakpoint-medium: 1366px;
$layout-breakpoint-small: 1100px;
$layout-breakpoint-xsmall: 480px;

// Colours
$background: #f2f2f2;
$white: #ffffff;
$black: #363636;
$danger: #ff4438;
$warning: #e58e1a;
$success: #387d2c;

    // Randomization
    $repeat: 10;
    $random-colors: #385cad, #e58e1a, #672666, #387d2c;

$perennia-light-blue: #385cad;
$perennia-blue: #003b4d;
$perennia-dark-blue: #002b4d;
$perennia-red: #ff4438;
$perennia-orange: #e58e1a;
$perennia-purple: #672666;
$perennia-green: #387d2c;
$perennia-grey: #b0b7bc;
$perennia-teal: #009999;
$perennia-teal-transparent: rgba(83, 164, 164, 0.2);
$perennia-dark-teal: rgb(0, 86, 80);

$perennia-primary: $perennia-light-blue;
$perennia-secondary: $perennia-teal;

// Table 
$table-foreground-color: $perennia-blue;
$table-row-hover-color: $perennia-teal-transparent;
$table-column-hover-color: $perennia-grey;
$table-full-width-tabs: true;
$table-border-radius: 10px;
$table-borders: false;
$table-border-color: $perennia-grey;
$table-secondary-border-color: ag-derived(border-color);
$table-row-border-color: ag-derived(secondary-border-color);


.adv-search-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    overflow: hidden;

    .adv-search-fields-container {
      display: grid;
      grid-template-columns: 3fr 3fr 3fr;
      justify-content: space-evenly;
      align-self: center;
      width: 90vw;
      margin: 10px;
      .adv-search-input-wrapper {
          margin: 10px;

          #adv-search-input {
            display: flex;
            flex-direction: column;
            margin: 5px;
            width: 100%;
            padding-left: 20px;
            height: 32px;
            border: 1px solid $perennia-grey;
            border-radius: 5px;
            padding-left: 10px;
            margin-bottom: 10px;
            & .adv-search-input-label {
                display: flex;
                align-items: center;
                text-transform: capitalize;
                margin-bottom: 5px;
                font-weight: 500;
                font-size: 16px;
                & span {
                    margin-left: 5px;
                    font-size: 12px;
                    color: $danger;
                }
            }
          }
      }
      @media (max-width: $layout-breakpoint-xsmall) {
        grid-template-columns: 3fr;
      }
    }

    .adv-search-btn-container {
      display: flex;
      width: 95%;
      justify-content: flex-end;
    }
  }
  
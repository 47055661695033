.header-container {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 100%;
    top: 0;
    background: linear-gradient($perennia-light-blue, $perennia-blue);
    box-shadow: 100px 5px 5px $perennia-grey;
    z-index: 200;
    @media (max-width: $layout-breakpoint-small) {
        box-shadow: none;
    }

    .header-logo-container {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 75px;
        width: 100px;
        top: 0;
        left: 0px;
        background-color: transparent;
    }

    .header-searchbar-container {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 200;
    }
    
    .header-btn-options-container {
        position: fixed;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 50px;
        width: auto;
        right: 10px;
        color: $white;
        & h3 {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5px;
            font-size: 14px;
            @media (max-width: $layout-breakpoint-xsmall) {
                font-size: 16px;
            }
            & span {
                margin-left: 5px;
            }
            &:hover {
                cursor: pointer;
                color: $perennia-grey;
                transition: 0.5s ease-in-out;
            }
        }
        .header-btn-dropdown {
            position: fixed;
            display: none;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            height: auto;
            width: auto;
            top: 48px;
            right: 10px;
            padding: 10px;
            background-color: $white;
            border-radius: 5px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
            transition: 850ms;
            &.is-active {
                display: flex;
            } 
            & p {
                display: flex;
                align-items: center;
                color: $black;
                font-size: 16px;
                margin: 5px;
                &:hover {
                    cursor: pointer;
                    color: $perennia-grey;
                    transition: 0.5s ease-in-out;
                }
                & span {
                    margin-left: 5px;
                    font-size: 14px;
                }
            }
        }
    }
}

@media (max-width: $layout-breakpoint-small) {
    .header-container {
        background: $perennia-blue;

        .header-logo-container {
            display: none;
        }
        // .header-searchbar-container {
        //     display: flex;
        //     width: 100vw;
        // }
    
        .header-btn-options-container {
            display: flex;
        }
        .logout {
            display: none;
        }
    }
}

.table-container {
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 100%;
    margin: 25px;
    padding: 5px;
    @media (max-width: $layout-breakpoint-small)  {
        justify-content: center;
        align-items: center;
        margin: 0;
    }
    .table-page-header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 48px;
        & p {
            font-size: 30px;
            font-weight: 600;
            letter-spacing: 2px;
            @media (max-width: $layout-breakpoint-small) {
                font-size: 24px;
                margin-bottom: 1em;
            }
        }
    }
    .table-btn-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 25px;
        width: 100%;
        height: 48px;
        @media (max-width: $layout-breakpoint-small) {
            justify-content: center;
            margin-right: 10px;
        }
        .table-info-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            width: 36px;
            border-radius: 50%;
            border: 2px solid $perennia-grey;
            background-color: $perennia-light-blue;
            color: $white;
            font-size: 20px;
            &:hover {
                cursor: pointer;
                opacity: 0.85;
                transition: 0.5s ease-in-out;
            }
        }
    }
    .table-grid {
        display: inline-block;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50vh;
        margin-top: 25px;
        @media (max-width: $layout-breakpoint-small) {
            height: 60vh;
        }
        .ag-theme-alpine {
            border: none;
            .ag-root-wrapper {
                border-top: none;
                border-radius: 5px;
            }
            .ag-center-cols-container {
                border: transparent;
                outline: none;
                .ag-row, .ag-row-first {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 42px !important;
                    font-size: 14px;
                    &:hover {
                        background-color: $background;
                    }
                    @media (max-width: $layout-breakpoint-small) {
                        width: 100%;
                        -ms-overflow-style: none;
                    }
                }
            }
            .ag-body-horizontal-scroll-viewport {
                scrollbar-width: thin;
            }
            .ag-body-viewport {
                overflow: visible !important;
                min-height: 40vh;
                border: none;
                outline: none;
                border-radius: 10px;
            }
            .ag-header {
                display: flex;
                align-items: center;
                height: 36px;
                background-color: $perennia-teal;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                margin-bottom: 10px;
                padding-left: 5px;
                .ag-header-cell-label {
                    color: $white;
                    font-weight: 600;
                    font-size: 18px;
                    letter-spacing: 2px;
                    &:hover {
                        opacity: 0.85;
                        color: $black;
                        transition: 0.5s ease-in-out;
                    }
                }
            }
            .ag-root-wrapper {
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
            }
            .ag-root-wrapper-body {
                height: fit-content !important;
                border: none;
            }
            .ag-overlay-no-rows-center {
                margin-top: 20px;
            }
            .ag-cell {
                display: flex;
                align-items: center;
                font-size: 12px;
                padding-left: 10px;
                overflow: hidden;
                .edit-table-item {
                    position: fixed !important;
                    display: none;
                    justify-content: center;
                    align-items: center;
                    width: 100% !important;
                    height: 100% !important;
                    top: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                    z-index: 1000000;
                    transition: 650ms;
                    &.is-Active {
                        display: flex;
                    }
                    .edit-table-item-modal {
                        position: fixed !important;
                        display: flex;
                        justify-content: center;
                        align-self: center;
                    }
                    form {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: auto;
                        .inputField-input {
                            height: 40px;
                        }
                        button {
                            margin-top: 40px;
                        }
                    } 
                    .user-edit {
                        display: flex;
                        flex-direction: column;
                        .inputField-input {
                            height: 48px;
                        }
                    }          
                }
            }
        }
        .toolbar-active {
            .ag-theme-alpine {
                .ag-header {
                    border-top-right-radius: 0px;
                }
            }
        }
    }
}
.table-action-btns {
    display: flex;
}
.table-tut {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 50%;
    transform: translateY(-50%);
    left: 0%;
    z-index: 500;
    transition: 650ms;
    &.is-notActive {
        display: none;
    }
    h2 {
      font-size: 20px;  
      margin: 10px;
    }
    p:nth-child(1) {
        font-style: italic;
    }
    p {
        margin: 10px;
    }
    .table-tut-images {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        img {
            width: 25vw;
            object-fit: contain;
            align-self: center;
            margin: 25px auto;
            border: 1px solid $perennia-grey;
        }
    }
    
}
.table-renderer-isFunder {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: $perennia-orange;
    height: 24px;
    width: 50px;
    & p {
        font-size: 12px;
    }
}
.status-icon {
    display: flex;
    align-items: center;
    height: 20px;
    width: 20px;
    &.pending {
        color: $perennia-orange;
    }
    &.approved {
        color: $perennia-green;
    }
    &.inactive {
        color: $perennia-red;
    }
}
.status-bar {
    display: flex;
    height: 16px;
    width: 100%;
    border-radius: 10px;
    margin-top: 10px;
    &.pending {
        background-color: $perennia-orange;
    }
    &.active {
        background-color: $perennia-light-blue;
    }
    &.inactive {
        background-color: $perennia-grey;
    }
}

.editor {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 50%;
    transform: translateY(-50%);
    left: 0%;
    z-index: 500;
    transition: 650ms;
    &.is-notActive {
        display: none;
    }
    .card {
        height: auto;
        @media (max-width: $layout-breakpoint-medium) {
            height: 650px;
            margin: 0 auto;
        }
        @media (max-width: $layout-breakpoint-small) {
            height: 800px;
        }
    }
    .editor-fields {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        width: 100%;
        @media (max-width: $layout-breakpoint-medium) {
            padding-bottom: 50px;
       }
    }
}
.item-update {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & button {
            background: none;
            border: none;
            font-size: 1.25em;  
            &:hover {
                cursor: pointer;
            }
        }
    }
}
.item-update-list {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 50%;
    transform: translateY(-50%);
    left: 0%;
    z-index: 500;
    transition: 650ms;
    .item-update-list-table-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        overflow: visible !important;
        .items-table-header {
            position: absolute;
            display: flex;
            align-items: center;
            width: 100%;
            height: 36px;
            top: 0;
            left: 0;
            margin-top: -10px;
            background-color: $background;
            border-bottom: 1px solid $perennia-grey;
            z-index: 1;
            & .items-table-button-container {
                position: absolute;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100px;
                height: 100%;
                right: 10px;
                & p {
                    font-size: 20px;
                    &:hover {
                        cursor: pointer;
                        color: $perennia-grey;
                        transition: 0.5s ease-in-out;
                    }
                }
            }
        }
    }
    #item-update-list-table {
        height: 100%;
        width: 100%;
        margin-top: 20px;
        .ag-row, .ag-row-first {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 42px !important;
            font-size: 14px;
            &:hover {
                background-color: $background;
            }
        }
    }
}
.item-overview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .item-main-info,
    .item-dates-info {
        display: flex;
        flex-direction: column;
        margin: 10px;
        width: 95%;
        border-bottom: 1px dotted $perennia-grey;
        &:last-child {
            border-bottom: none;
        }
        .item-name {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 10px;
        }
        & p {
            display: flex;
        }
        & p, ul {
            text-transform: capitalize;
            margin: 5px;
            font-size: 14px;
            font-weight: 600;
            & span {
                text-transform: capitalize;
                font-size: 12px;
                font-weight: 400;
                margin-left: 5px;
                & a {
                    color: $perennia-teal;
                    text-transform: none;
                    &:hover {
                        cursor: pointer;
                        color: $perennia-blue;
                        transition: 0.5s ease-in-out;
                    }
                }
            }
            & li {
                text-transform: capitalize;
                font-size: 12px;
                font-weight: 400;
                margin-top: 5px;
                & a {
                    color: $perennia-teal;
                    text-transform: none;
                    &:hover {
                        cursor: pointer;
                        color: $perennia-blue;
                        transition: 0.5s ease-in-out;
                    }
                }
            }
            .isCeu {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 14px;
                width: 14px;
                font-size: 12px;
                border-radius: 50%;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
                background-color: $danger;
                color: white;
                &.is-active {
                    background-color: $success;
                }
            }
        }
        .status {
            display: flex;
            justify-content: space-between;
            align-items: center;
            & p {
                align-items: center;
            }
            & button {
                display: flex;
                background: none;
                border: none;
                outline: none;
                &:hover {
                    cursor: pointer;
                    color: $perennia-grey;
                    transition: 0.4s ease-in-out;
                }
            }
        }
        .dp-paid {
            display: flex;
            align-items: center;
            color: $perennia-green;
            font-size: 24px;
        }
        .dp-not-paid {
            display: flex;
            align-items: center;
            color: $perennia-red;
            font-size: 24px;
        }
    }
}
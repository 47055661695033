.last-modified {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50px;
    right: 10px;
    height: 48px;
    z-index: 2;
    & p {
        font-weight: 500;
        & span {
            margin-left: 10px;
            font-weight: 400;
        }
    }
}
.item-description {
    display: flex;
    justify-content: center;
    height: 100%;
    & p {
        width: 90%;
        margin: 10px auto;
    }
}
.item-place-holder,
.grid-block {
    display: flex;
    @media (max-width: $layout-breakpoint-small) {
        display: none;
    }
}
.item-left-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15vw;
    height: auto;
    margin: 0 10px;
    @media (max-width: $layout-breakpoint-small) {
        width: 95%;
    }
    &.resource {
        width: 20vw;
    }
}
.item-middle-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30vw;
    height: auto;
    margin: 0 10px;
    @media (max-width: $layout-breakpoint-small) {
        width: 95%;
    }
}

.item-right-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40vw;
    height: auto;
    margin: 0 10px;
    @media (max-width: $layout-breakpoint-small) {
        width: 95%;
    }
    &.resource {
        width: 60vw;
    }
}
.item-bottom-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 92%;
    height: auto;
    margin: 0 10px;
    @media (max-width: $layout-breakpoint-medium) {
        width: 98%;
    }
    @media (max-width: $layout-breakpoint-small) {
        width: 95%;
    }
}
.no-permission {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    & h1 {
        font-size: 3.5em;
    }
}
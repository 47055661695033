.contact-swiper {
    position: relative;
    height: inherit;
    width: 95%;
    .swiper-button-prev,
    .swiper-button-next {
        position: absolute;
        top: 60%;
    }
    .swiper-slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 70%;
        height: 100%;
        margin-top: 10px;
        border-radius: 5px;
        border: 1px solid $perennia-grey;
        overflow: hidden;
        .contact-card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            width: 100%;
            background-color: $background;
            border-bottom: 1px solid $perennia-grey;
            & p {
                font-size: 16px;
                margin-left: 25px;
            }
            & a {
                margin-right: 25px;
                font-size: 14px;
                &:hover {
                    color: $perennia-teal;
                    transition: 0.4s ease-in-out;
                }
            }
        }
        .contact-card-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 175px;
            width: 100%;
            padding: 10px;
            & p {
                font-size: 14px;
                font-weight: 600;
                margin-left: 10px;
                margin-bottom: 5px;
                text-transform: capitalize;
            }
            & span {
                font-weight: 400;
                margin-left: 5px;
                & a {
                    text-transform: none;
                    color: $perennia-teal; 
                    &:hover {
                        color: $perennia-grey;
                        transition: 0.5s ease-in-out;
                    }
                }
            }
            .contact-isPrimary {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 24px;
                width: 125px;
                border-radius: 5px;
                font-size: 12px;
                font-weight: 500;
                margin-left: 10px;
                margin-bottom: 5px;
                color: $white;
                background-color: $perennia-purple;
            }
            .contact-isNotPrimary {
                height: 20px;
            }
        }
    }
}


.interaction-details {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 50%;
    transform: translateY(-50%);
    left: 0%;
    z-index: 500;
    transition: 650ms;
    &.is-notActive {
        display: none;
    }
}
.interaction {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
    .interaction-header {
        display: flex;
        justify-content: space-between;
        height: 36px;
        .interaction-header-left,
        .interaction-header-right {
            display: flex;
            align-items: center;
            font-size: 16px;
            height: 24px;
            .interaction-author-image {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 24px;
                width: 24px;
                border: 3px solid transparent;
                margin-right: 10px;
                border-radius: 50%;
                font-size: 20px;
                color: $perennia-grey;
                background-size: 100% 100%, 50% 50%, 50% 50%, 50% 50%, 50% 50%;
                background-repeat: no-repeat;
                background-image: linear-gradient(white, white), 
                        linear-gradient(30deg, $perennia-blue 36%, $perennia-light-blue 36%),
                        linear-gradient(120deg, $perennia-light-blue 36%, $perennia-green 36%),
                        linear-gradient(300deg, $perennia-orange 36%, $perennia-red 36%),
                        linear-gradient(210deg, $perennia-purple 36%, $perennia-purple 36%);
                background-position: center center, left top, right top, left bottom, right bottom;
                background-origin: content-box, border-box, border-box, border-box, border-box;
                background-clip: content-box, border-box, border-box, border-box, border-box;
            }
        }
    }
    .interaction-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 36px;
        width: 100%;
        margin-top: 10px;
    }
}
.action-btns-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    & button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px !important;
        width: 36px !important;
        border-radius: 5px;
        border: none;
        outline: none;
        &:hover {
            cursor: pointer;
            transition: 0.5s ease-in-out;
            background-color: #009999;
            color: #ffffff;
        }
    }
}
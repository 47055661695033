.MFA-items-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    p {
        font-size: 16px;
        margin-bottom: 10px;
        &:nth-child(2) {
            font-size: 10px;
        }
    }
    img {
        height: 15vh;
        width: 10vw;
        object-fit: cover;
        object-position: center;
        margin-bottom: 25px;
        border: none;
        outline: none;
    }
}
.admin-dashboard-container {
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 3fr;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: 80%;
    margin-top: 2.5vh;
    .admin-btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 25vh;
        margin: 25px;
        border-radius: 10px;
        padding: 10px;
        &:nth-child(6n + 1) {
            background-color: $perennia-blue;
            color: $perennia-blue;
        }
        &:nth-child(6n + 2) {
            background-color: $perennia-green;
            color: $perennia-green;
        }
        &:nth-child(6n + 3) {
            background-color: $perennia-teal;
            color: $perennia-teal;
        }
        &:nth-child(6n + 4) {
            background-color: $perennia-dark-teal;
            color: $perennia-dark-teal;
        }
        &:nth-child(6n + 5) {
            background-color: $perennia-purple;
            color: $perennia-purple;
        }
        &:nth-child(6n + 6) {
            background-color: $perennia-light-blue;
            color: $perennia-light-blue;
        }
        &:hover {
            opacity: 0.85;
            transition: 0.5s ease;
        }
        .admin-btn-bg-circle {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 10em;
            height: 10em;
            background-color: $white;
            border-radius: 50%;
            border: 5px solid $perennia-grey;
        } 
        .admin-btn-icon {
            display: flex;
            align-items: center;
            font-size: 3.5em;
        }
        .admin-btn-title {
            color: $white;
            font-size: 18px;
            margin-top: 10px;
            text-align: center;
            @media (max-width: $layout-breakpoint-small) {
                font-size: 16px;
            }
        }
    }
} 
@media (max-width: $layout-breakpoint-small) {
    .admin-dashboard-container {
        grid-template-columns: 3fr 3fr
    }
}
@media (max-width: $layout-breakpoint-xsmall) {
    .admin-dashboard-container {
        grid-template-columns: 3fr;
    }
    .admin-btn {
        margin: 0;
        margin-top: 10px;
        margin-bottom: 10px;
        &:hover {
            transition: none;
            transform: none;
        }
        .admin-btn-bg-circle {
            width: 7em;
            height: 7em;
        }
    }
}


.notes-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-bottom: 50px;
    .notes-header {
        position: absolute;
        display: flex;
        align-items: center;
        width: 100%;
        height: 36px;
        top: 0;
        left: 0;
        margin-top: -10px;
        background-color: $background;
        border-bottom: 1px solid $perennia-grey;
        .notes-button-container {
            position: absolute;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 50px;
            height: 100%;
            right: 10px;
            & p {
                font-size: 20px;
                &:hover {
                    cursor: pointer;
                    color: $perennia-grey;
                    transition: 0.5s ease-in-out;
                }
            }
        }
    }
}

.note-item-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    top: 25px;
    height: auto;
    width: 95%;
    border-radius: 5px;
    border: 1px solid $perennia-grey;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    margin-top: 10px;
    transition: 850ms;
    .note-item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 36px;
        .note-item-header-left,
        .note-item-header-right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            margin: 5px;
        }
        .note-item-header-left {
            font-size: 16px;
            .note-item-author-image {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
                width: 30px;
                border: 3px solid transparent;
                margin-right: 10px;
                border-radius: 50%;
                font-size: 26px;
                color: $perennia-grey;
                background-size: 100% 100%, 50% 50%, 50% 50%, 50% 50%, 50% 50%;
                background-repeat: no-repeat;
                background-image: linear-gradient(white, white), 
                        linear-gradient(30deg, $perennia-blue 36%, $perennia-light-blue 36%),
                        linear-gradient(120deg, $perennia-light-blue 36%, $perennia-green 36%),
                        linear-gradient(300deg, $perennia-orange 36%, $perennia-red 36%),
                        linear-gradient(210deg, $perennia-purple 36%, $perennia-purple 36%);
                background-position: center center, left top, right top, left bottom, right bottom;
                background-origin: content-box, border-box, border-box, border-box, border-box;
                background-clip: content-box, border-box, border-box, border-box, border-box;
            }
        }
        .note-item-header-right {
            & p {
                font-size: 12px;
            }
            .note-options-btn {
                font-size: 16px;
                &:hover {
                    cursor: pointer;
                    color: $perennia-grey;
                    transition: 0.5s ease-in-out;
                }
            }
            .note-item-author-options {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                height: 50px;
                width: 75px;
                top: 36px;
                right: 10px;
                border: 1px solid $perennia-grey;
                border-radius: 5px;
                background-color: $white;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
                transition: 850ms;
                & p {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    margin: 5px;
                    & span {
                        margin-left: 5px;
                    }
                    &:hover {
                        cursor: pointer;
                        color: $perennia-grey;
                        transition: 0.5s ease-in-out;
                    }
                }
            }
        }
    }
    .note-item-body {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 20px;
    }
    .note-item-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 30px;
        @for $i from 1 through $repeat {
            p:nth-child(#{length($random-colors)}n+#{$i}) {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 24px;
                width: auto;
                padding: 0 5px;
                font-size: 12px;
                font-weight: 500;
                text-transform: capitalize;
                background: nth($random-colors, random(length($random-colors)));
                color: $white;
                border-radius: 5px;
                margin: 10px;
            }
        }
        & h3 {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            font-size: 16px;
            color: $perennia-teal;
            & span {
                margin-left: 5px;
                font-size: 12px;
            }
            &:hover {
                cursor: pointer;
                color: $perennia-grey;
                transition: 0.5s ease-in-out;
            }
        }
    }
}

.note-item-reply-container {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 95%;
    margin-top: 10px;
    .note-item-reply-dropdown-icon {
        display: flex;
        align-items: center;
        margin-top: 25px;
        color: $perennia-blue;
        font-weight: 700;
        font-size: 16px;        
        &:hover {
            cursor: pointer;
            color: $perennia-grey;
            transition: 0.5s ease-in-out;
        }
    }
}

.add-notes-modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 50%;
    transform: translateY(-50%);
    left: 0%;
    z-index: 500;
    transition: 650ms;
    &.is-notActive {
        display: none;
    }
    .char-count {
        position: absolute;
        right: 20px;
        font-size: 10px;
    }
}


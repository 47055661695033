.change-password {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 50%;
    transform: translateY(-50%);
    left: 0%;
    z-index: 500;
    transition: 650ms;
    &.is-notActive {
        display: none;
    }

    .password-validate-warning {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 25px;
        & p {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 5px;
            color: $danger;
        }
        & li {
            font-size: 14px;
            margin: 5px;
        }
    }
}
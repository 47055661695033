.searchbar {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
}
.searchbar-options {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: $perennia-teal;
    height: 36px;
    min-width: 50px;
    width: auto;    
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    & p {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-left: 5px;
        & span {
            margin: 5px;
        }
    }
    &:hover {
        cursor: pointer;
        transition: 0.5s ease-in-out;
    }
}
.options-dropdown {
    position: absolute;
    background-color: $white;
    top: 45px;
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100px;
    min-height: 50px;
    transition: 850ms;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    &.active {
        display: flex;
    }
    & p {
        margin: 10px;
        &:hover {
            cursor: pointer;
            color: $perennia-grey;
            transition: 0.5s ease-in-out;
        }
    }
}
.searchbar-input {
    position: relative;
    display: flex;
    z-index: 2;
    & input {
        height: 36px;
        width: 25vw;
        border: none;
        outline: none;
        padding-left: 10px;
        background: rgba(0, 0, 0, 0.25);
        color: $white;
        &:focus {
            outline: none;
        }
    }
}
.searchbar-icon {
    display: grid;
    place-items: center;
    height: 36px;
    width: 38px;
    font-size: 18px;
    background: rgba(0, 0, 0, 0.25);
    color: $white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    &:hover {
        cursor: pointer;
    }
    .searchbar-icon-close {
        color: red;
    }
}
.searchbar-data-results {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 40vw;
    height: 300px;
    background-color: $white;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    z-index: 200;
    &::-webkit-scrollbar {
        display: none;
    }
    .searchbar-data-result {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        color: $black;
        border-bottom: 1px solid $perennia-grey;
        & p {
            margin-left: 10px;
        }
        &:hover {
            background-color: $background;
            transition: 0.5s ease-in-out;
        }
    }
}
.searchbar-mobile-icon {
    display: none;
}
@media (max-width: $layout-breakpoint-small) {
    .searchbar-container {
       position: absolute;
       display: none;
       justify-content: center;
       top: -1000%;
       &.active {
            display: flex;
            top: 0;
            background-color: $perennia-blue;
            width: 100vw;
            height: 48px;
            transition: 0.5s ease-in-out;
            z-index: 500;
            padding-top: 10px;
            .close-search-btn {
                position: fixed;
                top: 16px;
                right: 10px;
                font-size: 16px;
                z-index: 300;
                color: $white;
            }
       }
    }
    .searchbar-options {
        left: 10px;
        height: 24px;
        margin-right: 5px;
        & p {
            font-size: 12px;
        }
    }
    .options-dropdown > p {
       font-size: 12px;
    }
    .searchbar-input input  {
        top: 16px;
        width: 220px;
        height: 24px;
        background-color: $perennia-teal-transparent;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        color: white;
    }
    .searchbar-icon {
        display: none;
    }
    .searchbar-data-results {
        top: 40px;
        width: 95vw;
    }
}


.company-not-found-btn {
    display: flex;
    height: 20px;
    margin-left: 25px;
    & p {
        font-size: 12px;
        & span {
            margin-left: 5px;
            font-size: inherit;
            color: $perennia-teal;
            &:hover {
                cursor: pointer;
                color: $perennia-grey;
                transition: 0.5s ease-in-out;
            }
        }
    }
}
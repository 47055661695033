.delete-item-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: 650ms;
    & p {
        color: $danger;
        font-size: 12px;
    }
    &:hover {
        cursor: pointer;
        opacity: 0.75;
        transition: 0.4s ease-in-out;
    }
}
.load-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to top right, $background, $white);
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 100%;

    .load-text > p {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 4px;
      margin-top: 15px;
      color: $perennia-blue;
    }
    
    .load-animation {
      animation: rotate 1s infinite;  
      height: 50px;
      width: 50px;
      &::before, &::after {
        border-radius: 50%;
        content: '';
        display: block;
        height: 20px;  
        width: 20px;
      }
      &:before {
        animation: ball1 1s infinite;  
        background-color: $perennia-blue;
        box-shadow: 30px 0 0 grey;
        margin-bottom: 10px;
      }
      &:after {
        animation: ball2 1s infinite; 
        background-color: $perennia-orange;
        box-shadow: 30px 0 0 $perennia-blue;
      }
    }

    @keyframes rotate {
      0% { 
        -webkit-transform: rotate(0deg) scale(0.8); 
        -moz-transform: rotate(0deg) scale(0.8);
      }
      50% { 
        -webkit-transform: rotate(360deg) scale(1.2); 
        -moz-transform: rotate(360deg) scale(1.2);
      }
      100% { 
        -webkit-transform: rotate(720deg) scale(0.8); 
        -moz-transform: rotate(720deg) scale(0.8);
      }
    }
    
    @keyframes ball1 {
      0% {
        box-shadow: 30px 0 0 $perennia-purple;
      }
      50% {
        box-shadow: 0 0 0 $perennia-purple;
        margin-bottom: 0;
        -webkit-transform: translate(15px,15px);
        -moz-transform: translate(15px, 15px);
      }
      100% {
        box-shadow: 30px 0 0 $perennia-purple;
        margin-bottom: 10px;
      }
    }
    
    @keyframes ball2 {
      0% {
        box-shadow: 30px 0 0 $perennia-green;
      }
      50% {
        box-shadow: 0 0 0 $perennia-green;
        margin-top: -20px;
        -webkit-transform: translate(15px,15px);
        -moz-transform: translate(15px, 15px);
      }
      100% {
        box-shadow: 30px 0 0 $perennia-green;
        margin-top: 0;
      }
    }
  }
  
  
.App {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    background: linear-gradient(to top left, $background, $white);
    background-repeat: no-repeat;
    background-size: cover;
    letter-spacing: 0.05rem;
    line-height: normal;
    @media (max-width: $layout-breakpoint-small) {
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent; 
      }
    }
  }
  
  .framed-route-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50px;
    left: 100px;
    width: 95%;
    overflow: hidden;
    @media (max-width: $layout-breakpoint-medium) {
        width: 90%;
    } 
    @media (max-width: $layout-breakpoint-small) {
      left: 0;
      width: 100%;
  } 
  }

  .error-text {
    font-size: 12px;
    color: $danger;
    margin-left: 25px;
    width: 80%;
  }

  .btn-basic {
    border: none;
    background: transparent;
    font-size: 1em;
    font-weight: 500;
    height: 42px;
    transition: 650ms;
    &:hover {
      cursor: pointer;
      opacity: 0.75;
      transition: 0.4s ease-in-out;
    }
  }
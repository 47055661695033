.coming-soon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 90%;
    margin: auto;
    border-radius: 5px;
    border: 1px solid $perennia-grey;
    background-color: $background;
    & h3 {
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: 500;
    }
}
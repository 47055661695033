.tags-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    .tags-item {
        display: flex;
        flex-direction: column;
        width: 95%;
        height: 33%;
        margin: 10px;
        border-bottom: 1px dotted $perennia-grey;
        &:last-child {
            border-bottom: none;
        }
        .tags-item-header {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 30px;
            & h3 {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-weight: 600;
                font-size: 16px;
            }
        }
        & p {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-weight: 600;
        }
        & div {
           display: grid;
           grid-template-columns: auto auto;
           justify-content: center;
           align-items: center;
           @for $i from 1 through $repeat {
            p:nth-child(#{length($random-colors)}n+#{$i}) {
                display: flex;
                justify-content: center;
                align-items: center;
                height: auto;
                width: auto;
                padding:0 5px;
                font-size: 12px;
                text-align: center;
                background: nth($random-colors, random(length($random-colors)));
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
                color: $white;
                border-radius: 5px;
                margin: 10px 5px;
                padding: 2.5px;
               }
           }
        }
    }
}
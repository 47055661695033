.breadcrumb-container {
    position: fixed;
    display: flex;
    align-items: center;
    height: 48px;
    width: 100vw;
    top: 50px;
    left: 100px;
    background-color: $background;
    border-bottom: 1px solid $perennia-grey;
    z-index: 10;
    & p {
        font-weight: 400;
        font-size: 16px;
    }
    .username {
        margin-left: 10px;
    }
    .breadcrumb-go-back-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        width: auto;
        margin-left: 10px;
        & span {
            margin-right: 5px;
            margin-top: auto;
        }
        &:hover {
            cursor: pointer;
            color: $perennia-teal;
            transition: 0.5s ease-in-out;
        }
    }
    @media (max-width: $layout-breakpoint-small) {
        left: 0;
    }
}



